<template>
  <div>
    <div class="height-45 s_flex_bian_c width100">
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 1 ? "s_bf054 tw-text-white":"s_bf"' @click='tabClick(1)'>扫码界面</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 2 ? "s_bf054 tw-text-white":"s_bf"' @click='tabClick(2)'>物流码明细</div>
    </div>
    <div class="m-t-10" v-if='active == 1'>
      <van-form @submit='onSubmit'>
        <van-field v-model="form.warehousing_sn" name="warehousing_sn" label="入库单号" placeholder="入库单号" readonly required :rules="[{ required: true, message: '入库单号不能为空' }]">
          <template #button>
            <van-button color='#5B9DFF' size="small" type="primary"  @click="orderShow = true">按单入库</van-button>
          </template>
        </van-field>
        <van-field v-model="form.warehouse_name" is-link  readonly name="warehouse_name" label="入库仓库" placeholder="请选择入库仓库" required :rules="[{ required: true, message: '请选择入库仓库' }]" @click="()=>{if(isDisabled){return} warehouseShow = true}"/>
        <van-field name="use_product_batch" label="生产批次入库">
          <template #input>
            <van-switch v-model="form.use_product_batch" size="20" active-color='#5B9DFF' :disabled='isDisabled' />
          </template>
        </van-field>
        <van-field v-model="form.product_batch_name" is-link readonly name="product_batch_name" label="生产批次" placeholder="请选择生产批次" required :rules="[{ required: true, message: '请选择生产批次' }]" @click="()=>{if(isDisabled){return} batchShow = true}" v-if='form.use_product_batch'/>
        <van-field v-model="form.product_name" is-link readonly name="product_name" label="商品名称" placeholder="请选择商品" required :rules="[{ required: true, message: '请选择商品' }]" @click="()=>{if(isDisabled){return} goodsShow = true}"/>
        <van-field name="radio" label="扫码类型">
          <template #input>
            <van-radio-group v-model="form.scan_type" direction="horizontal" checked-color='#5B9DFF' @click='typeCheck = true'>
              <van-radio :name="1">扫码入库</van-radio>
              <van-radio :name="2">扫码回退</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.code_sn"  name="code_sn" label="二维码" autocomplete="off" placeholder="请输入二维码" required :rules="[{ required: true, message: '请输入二维码' }]">
          <template #button>
            <van-button style="width:66px" block color='#5B9DFF' size="small" type="primary" native-type="submit">{{form.scan_type == 1 ? '入库':'回退'}}</van-button>
          </template>
        </van-field>
        <div class="s_flex_bian_c s_wrap fz-14 p-15" v-if='showResult'>
          <p class="width50">入库物流码数量：{{scanCodeNum}}</p>
          <p class="width50">入库商品数量：{{successGoodsNum}}</p>
          <p class="width100 m-t-15" v-if='!typeCheck'>
            <span v-if='form.scan_type == 1'>扫码结果：{{resultCode == 0 ? '入库成功！':'入库失败！'}}</span>
            <span v-else>扫码结果：{{resultCode == 0 ? '回退成功！':'回退失败！'}}</span>
          </p>
          <p class="width100 m-t-15" v-if='resultCode != 0 && !typeCheck'>{{resultMsg}}</p>
        </div>
      </van-form>
    </div>
    <div class="m-t-10" v-if='active == 2'>
      <van-search
        v-model="search.keyword"
        show-action
        placeholder="请输入物流码进行搜索"
      >
        <template #action>
          <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div class="p-15" v-if='list.length'>
          <div class="s_flex_bian_c s_bf5 height-40 tw-border tw-border-gray-200 tw-border-solid tw-bg-gray-300 tw-bg-opacity-30">
            <p class="width33 s_flex_center">物流码</p>
            <p class="width33 s_flex_center">码类型</p>
            <p class="width33 s_flex_center">商品数量</p>
          </div>
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :immediate-check='false'
              finished-text="我也是有底线的~"
              @load="onLoad"
            >
              <div class="s_flex_bian_c height-40 tw-border tw-border-gray-200 tw-border-solid tw-border-t-0 s_bf" v-for="(item,index) in list" :key='index'>
                <p class="tw-text-center width33">{{item.code_sn}}</p>
                <p class="tw-text-center width33">{{item.code_type_text}}</p>
                <p class="tw-text-center width33">{{item.code_num}}</p>
              </div>
            </van-list>
          </van-pull-refresh>
      </div>
      <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无物流码" />
    </div>
    <!-- 选择仓库 -->
    <van-popup v-model:show="warehouseShow" position="bottom" round safe-area-inset-bottom>
      <selectWarehouse :showChild='1' @cancel='warehouseShow = false' @confirm='warehouseConfirm'></selectWarehouse>
    </van-popup>
    <!-- 选择商品 -->
    <van-popup v-model:show="goodsShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectGoods @select='goodsConfirm'></selectGoods>
    </van-popup>
    <!-- 选择批次 -->
    <van-popup v-model:show="batchShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectGoodBatch @select='batchConfirm'></selectGoodBatch>
    </van-popup>
    <!-- 选择订单 -->
    <van-popup v-model:show="orderShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectOrder orderTpye='in' @select='orderConfirm'></selectOrder>
    </van-popup>
    <audioPlay ref="audios" />

  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { setInWarehouse,getInWarehouseSn,getInWarehousingCode } from '@/api/inWarehouse.js';
  import { SplitArray } from "@/utils/util";
  import {useWare} from '@/hooks/function.js'
  let defForm = {
    warehousing_sn: '',
    warehouse_id: null,
    position_id: null,
    use_product_batch: 0,
    product_batch_id:'',
    product_id:'',
    product_sku_sn:'',
    scan_type:1,
    code_sn:'',
    warehouse_name:"默认仓库"
  };
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 20,
    keyword: ""
  };
  export default {
    setup() {
      const form = ref({ ...defForm });
      const search = ref({ ...defSearch });
      const state = reactive({
        active: 1,
        list:[],
        warehouseShow:false, //是否显示仓库选择
        goodsShow:false, //是否显示商品选择
        orderShow:false, //是否显示订单选择
        batchShow:false, //是否显示批次选择
        isDisabled:false, //是否禁用某些选项
        showResult:false, // 是否显示扫码结果
        scanCodeNum:0, //扫码数量
        successGoodsNum:0, //商品数量
        resultCode:'', //扫码结果code
        resultMsg:'', // 扫码结果提示语
        loading: false, // 是否处于加载状态，加载过程中不触发 load 事件
        finished: false, // 是否已加载完成，加载完成后不再触发 load 事件
        refreshing: false, // 是否处于加载中状态
        typeCheck:false, //扫码类型切换，暂时隐藏入库成功/回退成功
      })
      // 仓库选择
      const warehouseConfirm = (e) =>{
        console.log(e)
        form.value.warehouse_id = e[0].id;
        form.value.position_id = e[1] ? e[1].id : 0;
        form.value.warehouse_name = e[0].name+(e[1] ? '/'+e[1].name : '');
        state.warehouseShow = false
      }
      // 商品选择
      const goodsConfirm = (e) =>{
        form.value.product_id = e.id;
        form.value.product_name = (e.product_sku_name?e.product_sku_name:'单规格')+' - '+e.product_name;
        form.value.product_sku_sn = e.product_sku_sn || 0;
        state.goodsShow = false
      }
      // 批次选择
      const batchConfirm = (e) =>{
        form.value.product_id = e.product_id;
        form.value.product_name = e.product_name;
        form.value.product_sku_sn = e.product_sku_sn || 0;
        form.value.product_batch_id = e.id;
        form.value.product_batch_name = e.batch_name;
        state.batchShow = false
      }
      // 订单选择
      const orderConfirm = (e) =>{
        form.value.warehousing_sn = e.warehousing_sn;
        form.value.warehouse_id = e.warehouse_id;
        form.value.position_id = e.warehouse_position_id;
        form.value.use_product_batch = e.is_use_product_batch;
        form.value.product_batch_id = e.product_batch_id;
        form.value.product_id = e.product_id;
        form.value.product_sku_sn = e.product_sku_sn;
        form.value.warehouse_name = e.warehouse_name+(e.position_name ? '/'+e.position_name : ''); //仓库名称
        form.value.product_batch_name = e.product_batch_name; //批次名称
        form.value.product_name = (e.product_sku_name?e.product_sku_name:'单规格')+' - '+e.product_name; //商品名称
        state.orderShow = false;
        state.isDisabled = true;
        state.showResult = false; // 是否显示扫码结果
        state.scanCodeNum = 0; //扫码数量
        state.successGoodsNum = 0; //商品数量
        state.resultCode = ''; //扫码结果code
        state.resultMsg = ''; // 扫码结果提示语
      }
      // 获取单号
      const getSn = async() =>{
        let result = await getInWarehouseSn().then(res => res.data).catch(error => error)
        if (iscode(result)) {
          form.value.warehousing_sn = result.data.sn;
				}else{
          Toast.fail(result.msg);
				}
      }
      // tab点击
      const tabClick = (e) =>{
        state.active = e;
        if(e == 2){
          onRefresh()
        }
      }
      //获取明细
      const getList = async() =>{
        if (state.finished && state.loading) return
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await getInWarehousingCode({ ...search.value,sn:form.value.warehousing_sn}).then(res => res.data).catch(error => error)
        Toast.clear();
				if (iscode(result)) {
					var listData = result.data || [];
					var list = SplitArray(listData, state.list);
					var finished = listData.length < search.value.limit;
					state.list = list;
					state.finished = finished;
          state.loading = false;
				}else{
          Toast.fail(result.msg);
				}
      }
      // 上拉加载
      const onLoad = () => {
        search.value.page =  search.value.page+1
			  getList();
      };
      //下拉刷新
      const onRefresh = () => {
        state.finished = false;
        state.list = [];
        search.value = { ...search.value, page: 1 };
        getList();
      };
      const onSearch = (e) =>{
        onRefresh();
      }
      //入库
      const audios=ref('')
      const onSubmit = async() => {
        if(!form.value.code_sn){
          Toast.fail('请输入二维码');
          return
        }
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await setInWarehouse({ ...form.value}).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          Toast.success(result.msg);
          state.scanCodeNum = state.scanCodeNum+1
          state.isDisabled = true
          if(form.value.scan_type == 1){
            state.successGoodsNum = state.successGoodsNum+result.data.code_num
          }else{
            state.successGoodsNum = state.successGoodsNum-result.data.code_num
          }
				}else{
          audios.value.error()
          form.value.code_sn=''
          Toast.fail(result.msg);
				}
        state.resultCode = result.code
        state.resultMsg = result.msg
        state.showResult = true
        form.value.code_sn = ''
        state.typeCheck = false
      }
      onMounted(async() => {
        getSn();
        // 设置默认仓库
       let ware= await useWare().getWareList()
        form.value.warehouse_id = ware.data[0].id;
        form.value.position_id = 0;
      })
      return {
        ...toRefs(state),
        form,
        search,
        onSubmit,
        warehouseConfirm,
        goodsConfirm,
        batchConfirm,
        orderConfirm,
        onSearch,
        tabClick,
        onRefresh,
        onLoad,
        audios
      };
    }
  };
</script>
